import React from "react";
import "./EventsPage.css";

const EventsPage = () => {
  return (
    <div className="events-page">
      <div className="container">
        {/* Page Header Section */}
        <section className="page-header">
          <h2>Our Events</h2>
        </section>

        {/* Description Section */}
        <section className="events-description">
          <p>
            <strong>Both Rows</strong> is dedicated to building communities
            centered around mental health and sustainability—both in personal
            well-being and in fashion. We host and collaborate on events that
            encourage individuals to embrace their authentic selves fully. Our
            events are about freedom, growth, and the thrill of experiencing all
            emotions. They're designed to ignite serotonin receptors, creating
            moments of pure joy, while also focusing on the crucial mission of
            saving our planet and nurturing our souls.
          </p>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h3>
            <strong>Do You Want In?</strong>
          </h3>
        </section>

        {/* Events Sections */}
        <section className="event-section">
          <div className="event-content">
            <div className="event-image">
              <img
                src="/serotoninsoundwaves2.PNG"
                alt="Serotonin Soundwaves Flyer"
              />
            </div>
            <div className="event-description">
              <p>
                <em>Serotonin Soundwaves</em> is about bringing the community
                together through music, mental health awareness, and pure vibes.
                It's a day party where the rhythm takes over, emotions run high,
                and everyone is free to be themselves.
              </p>
              <button
                className="find-out-more-button"
                onClick={() =>
                  (window.location.href = "/events/serotoninsoundwaves")
                }
              >
                Find out More
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EventsPage;
